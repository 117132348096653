const config = {
  apiGateway: {
    REGION: 'eu-west-1',
    INTERNAL_API_URL: 'https://mm5dzvw757.execute-api.eu-west-1.amazonaws.com/prod',
    COMPANY_API_URL: 'https://flcf3gk1wg.execute-api.eu-west-1.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.11.1',
    HOSTNAME: 'https://company.staging.europe.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.europe.forwoodsafety.com',
    WEBSOCKET: 'wss://o2xqzbbzq1.execute-api.eu-west-1.amazonaws.com/staging'
  },
};

export default config;